<template>
  <div class="container">
    <BaseHeader :title="$t('views.claim.text_959')"> </BaseHeader>

    <div class="container-table">
      <div class="header-table">
        <div class="flex-sale pr-2">
          <div class="d-flex align-items-center flex-acoes">
            <div class="datas">
              <date-range-picker
                ref="picker"
                opens="right"
                v-model="dateRange"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :ranges="dateRanges"
                :class="{'heaven': isHeaven}"
                @update="fetchClaims"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template
                  v-else
                  v-slot:input
                  class="btn-table"
                  :title="$t('views.claim.text_2171')"
                >
                  {{ $t('views.claim.text_2172') }}
                </template>
              </date-range-picker>
            </div>
          </div>
        </div>

        <b-col cols="12" md="2" sm="12" class="pr-3 input-padding container-pesquisa">
          <b-form-select
            :placeholder="$t('views.claim.text_2173')"
            :options="[
              { value: null, text: $t('views.claim.text_2174') },
              { value: 'OPEN', text: $t('views.claim.text_2175') },
              { value: 'CLOSE', text: $t('views.claim.text_2176') },
            ]"
            v-on:input="changedFilter"
            size="sm"
            v-model="filterEvent"
            :class="{'heaven': isHeaven}"
          >
          </b-form-select>
        </b-col>

        <b-col cols="12" md="2" sm="12" class="pr-3 input-padding container-pesquisa">
          <b-form-select
            :options="[
              { value: null, text: $t('views.claim.text_962') },
              { value: 'chargedback', text: $t('views.claim.text_2186') },
              { value: 'refund', text: $t('views.claim.text_2187') },
            ]"
            size="sm"
            v-model="filterType"
            :class="{'heaven': isHeaven}"
          >
          </b-form-select>
        </b-col>

        <b-col cols="12" md="3" sm="12" class="pr-3 input-padding container-pesquisa">
          <b-form-select
            :options="[
              { value: 'not_closed', text: $t('views.claim.text_963') },
              { value: 'close', text: $t('views.claim.text_2188') },
              { value: 'close_solicitation', text: $t('views.claim.text_964') },
              { value: 'refund_pending', text: $t('views.claim.text_965') },
              { value: 'refunded', text: $t('views.claim.text_966') },
              { value: 'refund_denied', text: $t('views.claim.text_967') },
              { value: 'waiting_answer_seller', text: $t('views.claim.text_968') },
              { value: 'waiting_answer_client', text: $t('views.claim.text_969') },
            ]"
            size="sm"
            v-model="filterStatus"
            :class="{'heaven': isHeaven}"
          >
          </b-form-select>
        </b-col>

        <b-col class="container-pesquisa">
          <div class="inputSearch">
            <img src="@/assets/img/icons/search.svg" class="Pointer search" />
            <input
              style="max-height: 55px; margin-top: 5px"
              type="text"
              :placeholder="$t('views.claim.text_970')"
              v-on:input="debounce"
              v-model="search"
              class="input-busca"
            />
          </div>
        </b-col>
      </div>

      <!-- HEADER -->
      <b-row class="Table-header mb-1">
        <b-col cols="1" md="1" class="d-none d-md-block"> {{ $t('views.claim.text_2189') }} </b-col>

        <b-col cols="1" class="d-md-block">
          #ID
          <svg
            @click="toggleOrdemCrescente"
            :class="{
              ascendente: ordemCrescente == true,
              descendente: ordemCrescente == false,
            }"
            class="asc-des"
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.27656 0.166504C0.849387 0.166504 0.618932 0.667563 0.896935 0.9919L3.80982 4.39027C4.00937 4.62308 4.36953 4.62308 4.56908 4.39027L7.48197 0.991899C7.75997 0.667563 7.52952 0.166504 7.10234 0.166504H1.27656Z"
              :fill="isHeaven ? '#0070EB' : '#009488'"
            />
          </svg>
        </b-col>

        <b-col cols="2" md="2" class="d-none d-md-block"> {{ $t('views.claim.text_2177') }}</b-col>

        <b-col cols="6" sm="6" md="2" class="d-md-block"> {{ $t('views.claim.text_2178') }} </b-col>

        <b-col cols="1" class="d-none d-md-block"> {{ $t('views.claim.text_2179') }} </b-col>

        <b-col cols="4" md="3" class="d-none d-md-block"> {{ $t('views.claim.text_2180') }} </b-col>

        <b-col cols="1" md="1" class="ml-5"> {{ $t('views.claim.text_2181') }} </b-col>
      </b-row>
      <!-- END HEADER -->

      <!-- BODY -->
      <template v-if="!loading && sales.length > 0">
        <b-row class="Table-body" v-for="(item) in sales" :key="item.id">
          <!-- status -->
          <b-col cols="1" md="1" sm="1"
            class="d-none d-md-block"
            v-b-tooltip="{ title: getStatusClaim(item.status), placement: 'top' }"
          >
            <div style="display: flex; align-items: center">
              <div>
                <img
                  v-if="item.status === 'close' || item.status === 'refunded'"
                  class="pointer"
                  src="@/assets/img/icons/smile.svg"
                />
                <img v-else class="pointer" src="@/assets/img/icons/meh.svg" />
              </div>
            </div>
          </b-col>

          <!-- id -->
          <b-col cols="1" md="1" sm="1"  class="d-md-block"
            v-b-tooltip="{ title: '', placement: 'top' }"
          >
            <div style="display: flex; align-items: center">
              <div></div>
              <span> #{{ item.id }} </span>
            </div>
          </b-col>

          <!-- Criado em -->
          <b-col class="d-none d-md-block dados-cliente" cols="5" md="2">
            <div>
              {{ moment(item.created_at).format("DD/MM/YYYY HH:mm") }}
            </div>
          </b-col>

          <!-- cliente -->
          <b-col class="d-md-block dados-cliente" cols="6" md="2" sm="6">
            <div>
              {{ item.client.name }}
            </div>

            <div
              style="font-weight: 300; color: #999"
              :id="item.client.cpf_cnpj"
              v-b-tooltip="{ title: item.client.cpf_cnpj, placement: 'top' }"
            >
              {{ item.client.cpf_cnpj }}
            </div>
            <div style="font-weight: 300; color: #999">
              {{ item.client.cellphone }}
            </div>
          </b-col>

          <!-- venda -->
          <b-col class="d-none d-md-block dados-cliente" cols="5" md="1">
            <div class="blue--text">#{{ item.sale.id }}</div>

            <div style="font-weight: 300; color: #999">
              {{ item.sale.name }}
            </div>
            <div style="font-weight: 300; color: #999">
              R$ {{ formatMoney(item.sale.amount) }}
            </div>
          </b-col>

          <!-- reclamação -->
          <b-col class="d-none d-md-block dados-cliente" cols="5" md="3">
            <div>
              {{ item.category }}
            </div>

            <div style="font-weight: 300; color: #999">
              {{ item.objective }}
            </div>
          </b-col>

          <!-- Ações -->
          <b-col cols="2" md="1" class="ml-5">
            <div style="display: flex">
              <b-tooltip
                :target="`link-checkout-${item.id}`"
                title="Copiar Link do Checkout"
                placement="top"
              />
              <div
                @click="openModalViewClaim(item)"
                style="
                  cursor: pointer;
                  background: rgba(33, 51, 210, 0.1);
                  padding: 12px;
                  border-radius: 6px;
                "
              >
                <img
                  class="pointer"
                  style="width: 15px"
                  src="@/assets/img/icons/tratativa.svg"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col>
          <Paginate
            v-if="sales.length > 0 && !initExport"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>

      <div
        v-if="(loading && !sales.length) || (loading && initExport)"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <b-row
        v-if="!loading && !sales.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">{{ $t('views.claim.text_971') }}</p>
      </b-row>
      <!-- END BODY -->

      <!-- modals -->
      <FormViewClaim ref="formViewClaim" />
    </div>
    <CallEvaluation />
  </div>
</template>

<script>
import Paginate from '@/components/shared/Paginate'
import DateRangePicker from 'vue2-daterange-picker'
import Money from '@/mixins/money'
import moment from 'moment-timezone'
import FormViewClaim from '@/components/Seller/Claim/forms/viewClaim.vue'
import _ from 'lodash';
import { iconMethod } from '@/helpers.js'

import Claim from '@/services/resources/Claim'
const service = Claim.build()

export default {
  name: 'ListagemReclamações',
  components: { Paginate, DateRangePicker, FormViewClaim },
  data() {
    return {
      ordemCrescente: false,
      exportFilter: [],
      initExport: false,
      sales: [],
      sale_id: -1,
      search: null,
      loading: true,
      moment: moment,
      total: 0,
      itens: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      filters: {
        type: null,
        event: null,
        status: null,
      },
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: this.$t('locale_date.apply_label'),
        cancelLabel: this.$t('locale_date.cancel_label'),
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: this.$t('locale_date.days_of_week'),
        monthNames: this.$t('locale_date.month_names'),
        firstDay: 0,
      },
      dateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      client: {
        width: 0,
      },
    };
  },
  mixins: [Money],

  computed: {
    active() {
      return this.$route.name;
    },
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        [this.$t('locale_date.today')]: [today, today],
        [this.$t('locale_date.yesterday')]: [yesterday, yesterday],
        [this.$t('locale_date.last_week')]: [lastWeek, today],
        [this.$t('locale_date.current_month')]: [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        [this.$t('locale_date.current_year')]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        [this.$t('locale_date.last_month')]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        [this.$t('locale_date.every_period')]: [
          new Date(2020, 0, 1),
          new Date(today),
        ],
      };
    },
    filterEvent: {
      get: function () {
        if (this.filters.event) {
          return this.filters.event;
        } else {
          return null;
        }
      },
      set: function (val) {
        this.filters.event = val;
        this.changedFilter();
      },
    },
    filterStatus: {
      get: function () {
        if (this.filters.status) {
          return this.filters.status;
        } else {
          return "not_closed";
        }
      },
      set: function (val) {
        this.filters.status = val;
        this.changedFilter();
      },
    },
    filterType: {
      get: function () {
        if (this.filters.type) {
          return this.filters.type;
        } else {
          return null;
        }
      },
      set: function (val) {
        this.filters.type = val;
        this.changedFilter();
      },
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },

  methods: {
    toggleOrdemCrescente() {
      this.ordemCrescente = !this.ordemCrescente;
      this.sales = this.sales.reverse();
    },
    getStatusClaim(status) {
      if (status) {
        const possibleStatus = {
          close: this.$t('views.claim.text_2182'),
          close_solicitation: this.$t('views.claim.text_2190'),
          refund_pending: this.$t('views.claim.text_965'),
          refunded: this.$t('views.claim.text_966'),
          refund_denied: this.$t('views.claim.text_967'),
        };
        return possibleStatus[status];
      } else {
        return this.$t('views.claim.text_2183');
      }
    },
    getIconMethod(method) {
      return iconMethod(method);
    },
    getMethod(method) {
      return this.textMethod(method);
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf("+55") > -1) {
          var tell = tel.replace(/[() -]/g, "");
          if (tell[0] === "+") {
            tell = tell.substring(1);
          }
          return `https://api.whatsapp.com/send?phone=${tell}`;
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(
            /[() -]/g,
            ""
          )}`;
        }
      }
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    changedFilter() {
      if (this.filters.event || this.filters.status || this.filters.type) {
        this.fetchClaims();
      }
    },
    debounce: _.debounce(function () {
      this.fetchClaims(this.search);
    }, 500),
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchClaims(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchClaims(this.search);
    },
    filter(filters) {
      this.filters = filters;
      this.pagination.currentPage = 1;
      this.fetchClaims();
    },
    fetchClaims(search = null, exportData = false, dados_local = null) {
      if (typeof this.$route.query.s_id == "undefined") {
        this.$router.replace({
          query: {
            start_date: this.dateRange.startDate,
            end_date: this.dateRange.endDate,
            ...this.filters
          },
        });
      }

      this.loading = true;
      this.pagination.totalPages = 1;

      let data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };

      if (!exportData) {
        // reset
        this.pagination.totalPages = 1;
        this.sales = [];
        this.total = 0;
        this.itens = 0;
      } else {
        this.initExport = true;
        this.$bvToast.toast(this.$t('views.claim.text_2184'), {
          variant: "info",
          title: this.$t('views.claim.text_2185'),
          autoHideDelay: 8000,
          appendToast: true,
        });
      }

      if (search != null && typeof search == "string" && search.length > 0) {
        data.search = search;
      }
      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        data.date_start = moment(this.dateRange.startDate, "MM-DD-YYYY")
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DDT00:00:00-03:00");
        if (
          moment(this.dateRange.startDate).format("YYYY-MM-DD") ===
          moment(this.dateRange.endDate).format("YYYY-MM-DD")
        ) {
          data.date_end = moment(this.dateRange.endDate, "MM-DD-YYYY")
            .add(1, "day")
            .format("YYYY-MM-DD");
        } else {
          data.date_end = moment(this.dateRange.endDate, "MM-DD-YYYY")
            .endOf("day")
            .format("YYYY-MM-DD");
        }
      }

      Object.keys(this.filters).forEach(
        (key) =>
          (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key]
      );
      data = Object.assign(data, this.filters);

      if (exportData) {
        data.export = true;
        this.exportFilter = data;
      }

      service
        .search({ ...data, url: "/list" })
        .then((response) => {
          if (exportData) {
            this.download(response);
          } else {
            // salvando pesquisa no localStorage
            this.saveSearch(data);
            this.sales = response.data;
            this.pagination.totalPages = response.last_page;
            this.total = response.total;
            this.itens = response.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openModalViewClaim(item) {
      this.sale_id = item.id;
      this.$router
        .push({
          query: { s_id: item.sale_id, id: item.id, doc: item.client.cpf_cnpj },
        })
        .catch(() => {});
      this.$refs.formViewClaim.loading = true;
      this.$bvModal.show("modal-view-claim");
      service
        .search({
          sale_id: item.sale_id,
          cpf_cnpj: item.client.cpf_cnpj,
          id: item.id,
          url: `/list/${item.id}`,
        })
        .then((res) => {
          this.$refs.formViewClaim.fetch_claim_admin(
            res.claim,
            res.answers,
            item.client
          );
          this.$refs.formViewClaim.loading = false;
        })
        .catch((e) => {
          this.$refs.formViewClaim.loading = false;
        });
    },
    searchByFilter(dados_filter) {
      this.type_date = dados_filter.type;
      this.dateRange.startDate = this.decodeDateFilter(dados_filter.date_start);
      this.dateRange.endDate = this.decodeDateFilter(dados_filter.date_end);
      this.fetchClaims(null, false, dados_filter);
    },
    decodeDateFilter(date) {
      switch (date) {
        // hoje
        case "hoje":
          return moment()
        // ontem
        case "ontem":
          return moment().subtract(1, "days")
        // uma semana atras
        case "uma semana":
          return moment().subtract(7, "days")
        // primeiro dia do mes atual
        case "primeiro dia mes atual":
          return moment().startOf("month")
        // ultimo dia do mes atual
        case "ultimo dia mes atual":
          return moment().endOf("month")
        // primeiro dia do ANO atual
        case "primeiro dia ano":
          return moment().startOf("year")
        // ultimo dia do ANO atual
        case "ultimo dia ano":
          return moment().endOf("year")
        // primeiro dia do mes passado
        case "primeiro dia mes passado":
          return moment().startOf("month").subtract(1, "month")
        // ultimo dia do mes passado
        case "ultimo dia mes passado":
          return moment().endOf("month").subtract(1, "month").add(1, "days")
        default:
          return date
      }
    },
    saveSearch(dados_pesquisa) {
      window.localStorage.claim_filters = {};
      dados_pesquisa.date_start = this.formatDateFilter(
        dados_pesquisa.date_start
      );
      dados_pesquisa.date_end = this.formatDateFilter(dados_pesquisa.date_end);
      window.localStorage.claim_filters = JSON.stringify(dados_pesquisa);
    },
    formatDateFilter(date) {
      switch (date) {
        // hoje
        case moment().format("YYYY-MM-DD"):
          return "hoje"
        // ontem
        case moment().subtract(1, "days").format("YYYY-MM-DD"):
          return "ontem"
        // uma semana atras
        case moment().subtract(7, "days").format("YYYY-MM-DD"):
          return "uma semana"
        // primeiro dia do mes atual
        case moment().startOf("month").format("YYYY-MM-DD"):
          return "primeiro dia mes atual"
        // ultimo dia do mes atual
        case moment().endOf("month").format("YYYY-MM-DD"):
          return "ultimo dia mes atual"
        // primeiro dia do ANO atual
        case moment().startOf("year").format("YYYY-MM-DD"):
          return "primeiro dia ano"
        // ultimo dia do ANO atual
        case moment().endOf("year").format("YYYY-MM-DD"):
          return "ultimo dia ano"
        // primeiro dia do mes passado
        case moment().startOf("month").subtract(1, "month").format("YYYY-MM-DD"):
          return "primeiro dia mes passado"
        // ultimo dia do mes passado
        case moment().endOf("month").subtract(1, "month").format("YYYY-MM-DD"):
        case moment().endOf("month").subtract(1, "month").add(1, "days").format("YYYY-MM-DD"):
          return "ultimo dia mes passado"
        default:
          return date
      }
    },
    setQueryParams() {
      this.dateRange.startDate = this.$route.query.start_date || this.dateRange.startDate || moment();
      this.dateRange.endDate = this.$route.query.end_date || this.dateRange.endDate || moment();
      this.fetchClaims();
    },
    textMethod(method) {
      if(method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD": return this.$t('views.seller.edit_product.text_1112')
          case "CREDIT-CARD": return this.$t('views.seller.edit_product.text_1112')
          case "BANKTRANSFER": return this.$t('views.seller.edit_product.text_1113')
          case "CREDITCARD": return this.$t('views.seller.edit_product.text_1112')
          case "DEBITCARD": return this.$t('views.seller.edit_product.text_1114')
          case "debitcard": return this.$t('views.seller.edit_product.text_1114')
          case "TWO_CREDIT_CARDS": return this.$t('views.seller.edit_product.text_1115')
          case "DEBITCARD": return "CARTÃO DE DÉBITO"
          case "EFT": return "EFT"
          case "SPEI": return "SPEI"
          case "SENCILLITO": return "SENCILLITO"
          case "OXXO": return "OXXO"
          default: return method
        }
      } else return method;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    this.loading = true;
    // filtros salvos
    if (window.localStorage.claim_filters) {
      let filters = JSON.parse(window.localStorage.claim_filters);
      filters.date_start = this.$route.query.start_date || moment().format('MM-DD-YYYY');
      filters.date_end = this.$route.query.end_date || moment().format('MM-DD-YYYY');
      this.searchByFilter(filters);
    } else {
      this.dateRange.startDate = this.$route.query.start_date || moment().format('MM-DD-YYYY');
      this.dateRange.endDate = this.$route.query.end_date || moment().format('MM-DD-YYYY');
      this.fetchClaims();
    }

    if (typeof this.$route.query.s_id != "undefined") {
      this.sale_id = this.$route.query.s_id;
      setTimeout(() => {
        this.$bvModal.show("modal-view-claim");
        this.loading = false;
      }, 1000);
    } else {
      this.setQueryParams();
    }
  },
};
</script>

<style lang="scss" scoped>
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 148, 136, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 148, 136, 0.1);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(2) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 320px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 22px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}
.whats-cliente {
  font-size: 13px;
  color: #009488;
}
.blue--text {
  color: #2133d2;
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}
.input-padding {
  padding-top: 12px;
}
.ascendente {
  transform: rotate(180deg);
  filter: grayscale(0);

  cursor: pointer;
}
.descendente {
  transform: rotate(0deg);
  filter: grayscale(0);

  cursor: pointer;
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>
